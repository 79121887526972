<template>
  <form @submit.prevent="submit" class="form-wrapper step-1" :class="{'multiple': form.topup.length > 1 || isUserLogged}" novalidate>
    <div v-if="canShowContacts">
      <div class="form-row"
           v-for="(contact, index) in contacts"
           :key="index">
        <div class="with-checkbox">
          <input type="checkbox"
                 @change="toggleContact(contact)"
                 :checked="isContactActive(contact.msisdn)"
                 :id="'contact_' + index"
                 :name="'contact_' + index" />
          <label :for="'contact_' + index">{{ contact.msisdn | phone }} ({{ contact.description }})</label>
        </div>
        <p class="error-field"
           v-if="isContactError(contact.msisdn)"
           v-html="$t('view.component.topup.single.stage1.errorPhoneCheck')">
        </p>
      </div>
    </div>
    <div v-for="(topup, topupIndex) in form.topup" :key="topupIndex">
      <div class="form-row phone-number" :class="{last: isLastElement(topupIndex) && !isUserLogged}" v-if="!topup.isContact">
        <div class="input-wrapper">
          <input type="text"
                 inputmode="tel"
                 :class="{
                filled: !isPhoneEmpty(topupIndex),
                empty: isPhoneEmpty(topupIndex),
                touched: isPhoneTouched(topupIndex),
                untouched: !isPhoneTouched(topupIndex),
                dirty: isPhoneDirty(topupIndex),
                valid: isPhoneValid(topupIndex),
                invalid: !isPhoneValid(topupIndex),
                pristine: !isPhoneDirty(topupIndex)}"
                 ref="topupPhoneInput"
                 v-model="topup.phone"
                 v-only-number
                 autocorrect="off"
                 autocomplete="off"
                 spellcheck="false"
                 :disabled="isCheckPhoneInProgress(topupIndex)"
                 @focus="setPhoneTouched(topupIndex)"
                 @keyup="checkPhone(topupIndex)"
                 @paste="checkPhoneOnPaste($event, topupIndex)"
                 name="phone"
                 :id="'form-phone-' + topupIndex"
                 required
          />
          <label :for="'form-phone-' + topupIndex">{{ $t('view.component.topup.single.stage1.labelPhone') }}<span aria-hidden="true" class="asterisk">&nbsp;*</span></label>
          <button type="button"
                  class="remove-topup"
                  @click="removeTopup(topupIndex)"
                  v-if="canRemoveTopup() && !isCheckPhoneInProgress(topupIndex)"
                  :title="$t('view.accessibilities.readerWay.buttonsTitles.removeTopup')">
            <font-awesome-icon :icon="['fas', 'times']"/>
          </button>
          <Loader v-if="isCheckPhoneInProgress(topupIndex)" />
        </div>
        <p class="error-field" v-if="hasTopupError(topupIndex) && isPhoneEmpty(topupIndex)" v-html="$t('view.component.topup.single.stage1.errorPhoneRequired')"></p>
        <p class="error-field" v-if="hasTopupError(topupIndex) && !isPhoneEmpty(topupIndex) && isPhoneValidSet(topupIndex) && !isPhoneValid(topupIndex)" v-html="$t('view.component.topup.single.stage1.errorPhoneInvalid')"></p>
        <p class="error-field" v-if="hasTopupError(topupIndex) && isCheckPhoneError(topupIndex)" v-html="$t('view.component.topup.single.stage1.errorPhoneCheck')"></p>
      </div>
      <div class="form-row" v-if="isUserLogged && !topup.isContact">
        <div class="with-checkbox">
          <input type="checkbox"
                 required
                 v-model="topup.saveContact"
                 :id="'form-contact-save-' + topupIndex"
                 name="contact-save" />
          <label :for="'form-contact-save-' + topupIndex" v-html="$t('view.component.topup.single.stage1.saveContactLabel')"></label>
        </div>
        <p class="hint-field bold" v-if="!topup.saveContact"  v-html="$t('view.component.topup.single.stage1.saveContactInfo')"></p>
      </div>
      <div class="form-row" v-if="isUserLogged && !topup.isContact && topup.saveContact">
        <div class="input-wrapper">
          <input type="text"
                 autocorrect="off"
                 autocomplete="off"
                 spellcheck="false"
                 :class="{
                filled: !isContactNameEmpty(topupIndex),
                empty: isContactNameEmpty(topupIndex),
                touched: isContactNameTouched(topupIndex),
                untouched: !isContactNameTouched(topupIndex),
                dirty: isContactNameDirty(topupIndex),
                valid: isContactNameValid(topupIndex),
                invalid: !isContactNameValid(topupIndex),
                pristine: !isContactNameDirty(topupIndex)}"
                 v-model="topup.contact.name"
                 required
                 :id="'form-contact-name-' + topupIndex"
                 name="contact-name" />
          <label :for="'form-contact-name-' + topupIndex">{{ $t('view.component.topup.single.stage1.labelName') }}<span aria-hidden="true" class="asterisk">&nbsp;*</span></label>
        </div>
        <p class="error-field" v-if="hasContactNameError(topupIndex)" v-html="$t('validation.required')"></p>
      </div>
    </div>

    <div class="form-row add-topup-row" v-if="canAddTopup()">
      <button type="button" class="add-number" @click="addTopup">{{ $t('view.component.topup.single.stage1.addPhone') }}</button>
    </div>
    <div class="form-row required-fields" v-if="!isUserLogged">
      <div class="input-wrapper">
        <input type="text"
               inputmode="email"
               v-model="form.email"
               :class="{
                filled: !isEmailEmpty(),
                empty: isEmailEmpty(),
                touched: isEmailTouched(),
                untouched: !isEmailTouched(),
                dirty: isEmailDirty(),
                valid: isEmailValid(),
                invalid: !isEmailValid(),
                pristine: !isEmailDirty()}"
               @focus="setEmailTouched"
               @keyup="checkEmail"
               autocorrect="off"
               autocomplete="off"
               spellcheck="false"
               id="email"
               required />
        <label for="email">{{ $t('view.component.topup.single.stage1.labelEmail') }}<span aria-hidden="true" class="asterisk">&nbsp;*</span></label>
      </div>
      <p class="hint-field bold" v-if="!hasEmailError()">{{ $t('view.component.topup.single.stage1.emailDescription') }}</p>
      <p class="error-field"  v-if="hasEmailError() && isEmailEmpty()" v-html="$t('view.component.topup.single.stage1.errorEmailRequired')"></p>
      <p class="error-field"  v-if="hasEmailError() && !isEmailEmpty() && !isEmailValid()" v-html="$t('view.component.topup.single.stage1.errorEmailInvalid')"></p>
      <p class="required-fields" aria-hidden="true"><span>*</span>{{ $t('view.component.topup.single.stage1.fieldsRequired') }}</p>
    </div>
    <div class="form-row submit-button-wrapper mb-0">
      <button class="primary"
              type="submit"
              :class="{disabled: !isFormValid()}"
              :disabled="isLoading || isMaintenanceGlobal">
        {{ $t('view.component.topup.single.stage1.buttonNext') }}
      </button>
    </div>
  </form>
</template>

<script>
import TopupStep1Mixin from '@/common/mixins/Topup/Stage1.mixin'
import { IS_MAINTENANCE_GLOBAL } from '@/common/const/maintenance.const'
import { ROUTING } from '@/orange/const/routing.const'
import Loader from '../../Loader'
import { GoogleAnalytics } from '@/common/class/ga.class'

export default {
  name: 'TopupSingleFormStep1',
  components: { Loader },
  mixins: [TopupStep1Mixin],
  computed: {
    formTopupLength: function () {
      return this.form.topup.length
    }
  },
  watch: {
    formTopupLength: function (value) {
      if (!value) {
        this.addTopup()
      }
    }
  },
  methods: {
    isLastElement (topupIndex) {
      return (this.form.topup.length - 1) === topupIndex && this.canAddTopup()
    },
    submit (e) {
      e.preventDefault()
      this.formError = false
      this.checkEmail()

      if (IS_MAINTENANCE_GLOBAL) {
        return false
      }

      if (!this.isFormValid()) {
        this.formError = true
        return
      }

      this.setStage1Data(this.form)
        .then(() => {
          this.SET_TOPUP_STAGE(2)
          this.$router.push(ROUTING.topup.single.step2)
        })
    }
  },
  mounted () {
    GoogleAnalytics.sendEvent({ event: 'prepaid_funnel', event_name: 'podaj_dane', step: 'step_1' })
  }
}
</script>

<style lang="scss" src="../../../assets/scss/components/_topup-main.scss" scoped></style>
