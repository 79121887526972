<template>
  <div>
    <div class="container">
      <div class="inner">
        <div class="top-bar">
          <h1 class="main-title">{{ $t('view.component.topup.single.header') }}</h1>
          <TopupSingleSteps />
        </div>
        <TopupSingleFormStep1 />
        <BannerSlider />
      </div>
    </div>
  </div>
</template>

<script>
import TopupSingleFormStep1 from '@/orange/components/Topup/Single/FormStep1'
import TopupSingleSteps from '@/orange/components/Topup/Single/Steps'
import BannerSlider from '../../../components/BannerSlider'

export default {
  name: 'TopupSingleStep1View',
  components: {
    BannerSlider,
    TopupSingleSteps,
    TopupSingleFormStep1
  }
}
</script>

<style lang="scss" src="../../../assets/scss/components/_topup-main.scss" scoped></style>
