import { TOPUP_LANG_COOKIE } from '../../const/topup.const'
import { CookieClass } from '../../class/cookie.class'

export default {
  methods: {
    supportLangParam () {
      let newLocale = this.$route.query.lang
      let currentLocale = this.$i18n.locale()
      let localesList = this.$i18n.locales()

      if (newLocale && newLocale.length &&
        localesList.includes(newLocale) &&
        currentLocale !== newLocale) {
        this.$i18n.set(newLocale)
        CookieClass.setCookie(TOPUP_LANG_COOKIE, newLocale, 7)
        return
      }

      let localeFromCookie = CookieClass.getCookie(TOPUP_LANG_COOKIE)
      if (localeFromCookie && localeFromCookie.length &&
        localesList.includes(localeFromCookie) &&
        currentLocale !== localeFromCookie) {
        this.$i18n.set(localeFromCookie)
      }
    }
  },
  created () {
    this.supportLangParam()
  }
}
