<template>
  <div class="slider-wrapper" v-if="activeBanners.length">
    <div class="slides">
      <div :style="{ transform: getTranslateValue() }"
           v-swipe
           @swipe.left="setNextSlideActive"
           @swipe.right="setPrevSlideActive"
           @mouseover="stopSlideChangeTimeout()"
           @mouseout="startSlideChangeTimeout()">
        <a v-for="(banner, index) in bannersList"
           :key="`banner_${index}`"
           :href="banner.url"
           :target="banner.target">
          <img :src="getBannerSrc(banner.img)" :alt="banner.alt" />
        </a>
      </div>
    </div>

    <div class="controls">
      <button class="start-stop" @click="toggleSlideChangeTimeout()" :class="{'is-playing': !!slideChangeHandler, 'is-paused': !slideChangeHandler}"></button>
      <ul>
        <li v-for="(banner, index) in activeBanners"
            :key="`control_${index}`"
            @click="setActiveSlide(index)"
            class="control">
          <button class="button"
                  type="button"
                  :class="{ active: isSlideActive(index) }"
                  :style="{ width: windowSize === 'm' ? getSlidePercentProgress(index) + '%' : '100%'}">
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BannersMixin from '@/common/mixins/Topup/Banners.mixin'
import swipeDirective from '@/common/directives/swipe'

export default {
  name: 'BannerSlider',
  mixins: [BannersMixin],
  directives: {
    swipe: swipeDirective
  }
}
</script>

<style lang="scss" src="../assets/scss/components/_banner-slider.scss"></style>
