export default {
  methods: {
    supportPhoneParam () {
      let phone = this.$route.query.phone
      if (!phone) {
        return
      }

      this.form.topup[0].phone = phone
      this.checkPhone(0)
    }
  }
}
