import { render, staticRenderFns } from "./Step1.vue?vue&type=template&id=2bc4ec52&scoped=true"
import script from "./Step1.vue?vue&type=script&lang=js"
export * from "./Step1.vue?vue&type=script&lang=js"
import style0 from "../../../assets/scss/components/_topup-main.scss?vue&type=style&index=0&id=2bc4ec52&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc4ec52",
  null
  
)

export default component.exports