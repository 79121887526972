let startTouchEvent

export default {
  bind: function (el) {
    el.addEventListener('touchstart', (e) => {
      startTouchEvent = e
    })
    el.addEventListener('touchend', (e) => {
      const timeDuration = e.timeStamp - startTouchEvent.timeStamp
      const touchDistanceX = e.changedTouches[0].screenX - startTouchEvent.changedTouches[0].screenX
      const touchDistanceY = e.changedTouches[0].screenY - startTouchEvent.changedTouches[0].screenY

      if (timeDuration > 600) {
        return
      }
      if (Math.abs(touchDistanceY) > 100) {
        return
      }
      if (Math.abs(touchDistanceX) < 150) {
        return
      }
      const event = new CustomEvent('swipe')
      if (touchDistanceX < 0) {
        // podpięcie się pod modyfikator left
        event.button = 0
      }
      if (touchDistanceX > 0) {
        // podpięcie się pod modyfikator right
        event.button = 2
      }
      el.dispatchEvent(event)
    })
  }
}
