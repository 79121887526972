<template>
  <svg width="75" height="75" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" class="loader">
    <path id="p1" d="m25 25h100v100h-100z" :fill="color" ></path>
    <path id="p2" d="m39.29 96.43h71.43v14.29h-71.43z" fill="#fff"/>
    <path d="m0 0h150v150h-150z" fill="none"/></svg>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    color: {
      type: String,
      default: '#ff7900'
    }
  }
}
</script>

<style>
#p1 {
  -webkit-animation: kwadrat 2.5s infinite;
  animation: kwadrat 2.5s infinite;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
#p2 {
  -webkit-animation: pasek 2.5s infinite;
  animation: pasek 2.5s infinite;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transform-origin: 26%;
  -ms-transform-origin: 26%;
  transform-origin: 26%;
}

@-webkit-keyframes kwadrat {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50%, 100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

}
@keyframes kwadrat {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50%, 100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

}
@-webkit-keyframes pasek {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 26%;
    transform-origin: 26%;
  }
  47% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 26%;
    transform-origin: 26%;
  }
  74% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 26%;
    transform-origin: 26%;
  }
  75% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 74%;
    transform-origin: 74%;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 74%;
    transform-origin: 74%;
  }
}
@keyframes pasek {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 26%;
    transform-origin: 26%;
  }
  47% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 26%;
    transform-origin: 26%;
  }
  74% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 26%;
    transform-origin: 26%;
  }
  75% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 74%;
    transform-origin: 74%;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 74%;
    transform-origin: 74%;
  }
}
</style>
