import { TOPUP_PHONE_WITHOUT_PREFIX_LENGTH, TOPUP_TEMPLATE } from '../../const/topup.const'
import { ToolsClass } from '../../class/tools.class'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import phoneParamMixin from '../params/phone.mixin'
import emailParamMixin from '../params/email.mixin'
import tokenParamMixin from '../params/token.mixin'
import amountParamMixin from '../params/amount.mixin'
import langParamMixin from '../params/lang.mixin'
import { GoogleAnalytics } from '@/common/class/ga.class'
import { IS_MAINTENANCE_GLOBAL } from '@/common/const/maintenance.const'

export default {
  mixins: [
    langParamMixin,
    amountParamMixin,
    phoneParamMixin,
    emailParamMixin,
    tokenParamMixin
  ],
  data () {
    return {
      form: {
        topup: [],
        email: null,
        isEmailValid: null,
        isEmailEmpty: true,
        isEmailDirty: false,
        isEmailTouched: false
      },
      formError: false,
      MAX_TOPUPS: this.$VUE_TOPUP_CONFIG.maxTopups,
      isMaintenanceGlobal: IS_MAINTENANCE_GLOBAL
    }
  },
  watch: {
    isUserLogged: function (value) {
      if (value === true) {
        this.form.email = this.userEmail
        this.checkEmail()
      }
    },
    contactsList: function (value) {
      if (value.length) {
        this.removeEmptyTopup()
      }
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.topup.loading || state.user.loading || state.contacts.loading,
      stage: state => state.topup.stage
    }),
    ...mapGetters({
      formData: 'getData',
      isUserLogged: 'isUserLogged',
      userEmail: 'getUserEmail',
      contacts: 'getContactsList'
    }),
    canShowContacts: function () {
      return this.isUserLogged && this.contacts.length
    },
    isAnyTopupInvalid: function () {
      let result = false
      this.form.topup.forEach(item => {
        if (item.phone !== null && String(item.phone).length > 0 && !item.isPhoneValid) {
          result = true
        }
      })
      return result
    },
    isAnyTopupEmpty: function () {
      let result = false
      this.form.topup.forEach(item => {
        if (item.phone === null || String(item.phone).length === 0) {
          result = true
        }
      })
      return result
    },
    isNoTopupSet: function () {
      if (!this.form.topup.length) {
        return true
      }
      let result = true
      this.form.topup.forEach(item => {
        if (item.phone !== null && String(item.phone).length > 0) {
          result = false
        }
      })
      return result
    },
    isAnyTopupCheckphoneError: function () {
      return !!this.form.topup.filter(topup => topup.isCheckPhoneStatusValid === false).length
    },
    topupsWithCheckphoneError: function () {
      return this.form.topup.filter(topup => topup.isCheckPhoneStatusValid === false)
    }
  },
  methods: {
    ...mapMutations([
      'SET_TOPUP_STAGE',
      'RESET_TOPUP'
    ]),
    ...mapActions([
      'setStage1Data',
      'checkPhoneStatus'
    ]),
    getContactByPhone (phone) {
      if (!this.contacts || !this.contacts.length) {
        return false
      }
      phone = ToolsClass.cleanPhoneNumber(phone)
      phone = ToolsClass.getPhoneNumberWithoutPrefix(phone)
      return this.contacts.find(contact => ToolsClass.getPhoneNumberWithoutPrefix(contact.msisdn) === phone)
    },
    isPhoneInContacts (phone) {
      return !!this.getContactByPhone(phone)
    },
    setPhoneContact (topupIndex, contact) {
      if (this.form.topup[topupIndex]) {
        this.form.topup[topupIndex].isContact = true
        this.form.topup[topupIndex].contact = contact
      }
    },
    toggleContact (contact) {
      this.formError = false
      if (this.isContactActive(contact.msisdn)) {
        this.removeContactFromTopup(contact.msisdn)
        return
      }
      this.removeEmptyTopup()
      this.addContactToTopup(contact)
    },
    isContactChecking (number) {
      if (!this.isContactActive(number)) {
        return false
      }
      const index = this.getTopupIndexByPhoneNumber(number)
      return this.isCheckPhoneInProgress(index)
    },
    isContactActive (number) {
      return this.getTopupIndexByPhoneNumber(number) > -1
    },
    isContactError (number) {
      if (!this.isContactActive(number)) {
        return false
      }
      const index = this.getTopupIndexByPhoneNumber(number)
      return this.isCheckPhoneError(index)
    },
    getTopupIndexByPhoneNumber (number) {
      let result = this.form.topup.find(
        topup => ToolsClass.cleanPhoneNumber(topup.phone) ===
          ToolsClass.cleanPhoneNumber(ToolsClass.getPhoneNumberWithoutPrefix(number)))
      return this.form.topup.indexOf(result)
    },
    getEmptyTopupIndex () {
      let result = this.form.topup.find(topup => topup.phone === null)
      return this.form.topup.indexOf(result)
    },
    removeEmptyTopup () {
      let emptyTopupIndex = this.getEmptyTopupIndex()
      if (emptyTopupIndex === -1) {
        return false
      }
      this.removeTopup(emptyTopupIndex)
    },
    addContactToTopup (contact) {
      if (!this.canAddTopup()) {
        if (!this.isAnyNonContactTopup()) {
          return false
        }
        this.removeEmptyTopup()
      }
      this.form.topup.push(Object.assign({}, TOPUP_TEMPLATE,
        { phone: ToolsClass.getPhoneNumberWithoutPrefix(contact.msisdn), contact: contact, isContact: true }))
      const index = this.getTopupIndexByPhoneNumber(contact.msisdn)
      this.checkPhone(index)
    },
    removeContactFromTopup (number) {
      const index = this.getTopupIndexByPhoneNumber(number)
      this.removeTopup(index)
    },
    isAnyNonContactTopup () {
      return !!this.form.topup.filter(topup => !topup.isContact).length
    },
    canAddTopup () {
      return this.form.topup.length < this.$VUE_TOPUP_CONFIG.maxTopups
    },
    canRemoveTopup () {
      return this.form.topup.length > this.$VUE_TOPUP_CONFIG.minTopups
    },
    addTopup () {
      if (this.canAddTopup()) {
        this.form.topup.push(ToolsClass.deepExtend(TOPUP_TEMPLATE))
      }
    },
    removeTopup (index) {
      this.form.topup.splice(index, 1)
    },
    isFormErrorToDisplay () {
      return this.formError && (this.isNoTopupSet || !this.isEmailValid() || this.isAnyTopupCheckphoneError)
    },
    setCheckPhoneProgressFlag (topupIndex, value) {
      if (this.form.topup[topupIndex]) {
        this.form.topup[topupIndex].checkPhoneInProgress = value
      }
    },
    setCheckPhoneStatusFlag (topupIndex, value) {
      if (this.form.topup[topupIndex]) {
        this.form.topup[topupIndex].isCheckPhoneStatusValid = value
      }
    },
    isCheckPhoneInProgress (topupIndex) {
      return this.form.topup[topupIndex].checkPhoneInProgress
    },
    isCheckPhoneStatusSet (topupIndex) {
      return this.form.topup[topupIndex].isCheckPhoneStatusValid !== null
    },
    isCheckPhoneStatusValid (topupIndex) {
      if (this.form.topup[topupIndex]) {
        return this.form.topup[topupIndex].isCheckPhoneStatusValid
      }
      return false
    },
    isCheckPhoneError (topupIndex) {
      return this.isCheckPhoneStatusSet(topupIndex) && !this.isCheckPhoneStatusValid(topupIndex)
    },
    isPhoneValidSet (topupIndex) {
      return this.form.topup[topupIndex].isPhoneValid !== null
    },
    isPhoneValid (topupIndex) {
      return this.form.topup[topupIndex].isPhoneValid
    },
    isPhoneEmpty (topupIndex) {
      return this.form.topup[topupIndex].isPhoneEmpty
    },
    isPhoneDirty (topupIndex) {
      return this.form.topup[topupIndex].isPhoneDirty
    },
    isPhoneTouched (topupIndex) {
      return this.form.topup[topupIndex].isPhoneTouched
    },
    setPhoneValid (topupIndex, value) {
      this.form.topup[topupIndex].isPhoneValid = value
    },
    setPhoneDirty (topupIndex, value) {
      this.form.topup[topupIndex].isPhoneDirty = value
    },
    setPhoneTouched (topupIndex) {
      this.form.topup[topupIndex].isPhoneTouched = true
    },
    setPhoneEmpty (topupIndex, value) {
      this.form.topup[topupIndex].isPhoneEmpty = value
    },
    hasTopupError (topupIndex) {
      if (this.formError) {
        return this.isPhoneEmpty(topupIndex) || (this.isPhoneValidSet(topupIndex) && !this.isPhoneValid(topupIndex)) || this.isCheckPhoneError(topupIndex)
      }
      return false
    },
    isEmailValidSet () {
      return this.form.isEmailValid !== null
    },
    isEmailValid () {
      return this.form.isEmailValid
    },
    isEmailEmpty () {
      return this.form.isEmailEmpty
    },
    isEmailDirty () {
      return this.form.isEmailDirty
    },
    isEmailTouched () {
      return this.form.isEmailTouched
    },
    hasEmailError () {
      if (this.formError) {
        return this.isEmailEmpty() || (this.isEmailValidSet() && !this.isEmailValid())
      }
      return false
    },
    setEmailValid (value) {
      this.form.isEmailValid = value
    },
    setEmailDirty (value) {
      this.form.isEmailDirty = value
    },
    setEmailTouched () {
      this.form.isEmailTouched = true
    },
    setEmailEmpty (value) {
      this.form.isEmailEmpty = value
    },
    hasContactNameError (topupIndex) {
      if (this.formError) {
        return this.isContactNameEmpty(topupIndex)
      }
      return false
    },
    isContactNameEmpty (topupIndex) {
      return !this.form.topup[topupIndex].contact.name ||
        this.form.topup[topupIndex].contact.name === null ||
        this.form.topup[topupIndex].contact.name === ''
    },
    isContactNameTouched (topupIndex) {
      return !!this.form.topup[topupIndex].contact.name
    },
    isContactNameDirty (topupIndex) {
      return !!this.form.topup[topupIndex].contact.name
    },
    isContactNameValid (topupIndex) {
      return typeof this.form.topup[topupIndex].contact.name === 'string' && this.form.topup[topupIndex].contact.name.length > 0
    },
    checkEmail () {
      let email = this.form.email
      this.setEmailValid(null)

      if (!email || !email.length) {
        this.setEmailEmpty(true)
        return
      }
      this.setEmailEmpty(false)
      this.setEmailDirty(true)

      if (!ToolsClass.isEmailValid(email)) {
        this.setEmailValid(false)
        return
      }

      this.setEmailValid(true)
    },
    checkPhoneOnPaste (event, topupIndex) {
      let paste

      if (window.clipboardData && window.clipboardData.getData) {
        paste = window.clipboardData.getData('Text')
      } else if (event.clipboardData && event.clipboardData.getData) {
        paste = (event.clipboardData || window.clipboardData).getData('text')
      }

      if (paste && paste.length) {
        this.form.topup[topupIndex].phone = paste
        this.checkPhone(topupIndex)
      }
    },
    checkPhone (topupIndex) {
      let phone = String(this.form.topup[topupIndex].phone)
      this.setPhoneDirty(topupIndex, true)
      this.setPhoneValid(topupIndex, null)
      this.setCheckPhoneStatusFlag(topupIndex, null)
      this.setCheckPhoneProgressFlag(topupIndex, false)

      // czy numer pusty
      if (!phone || !phone.length) {
        this.setPhoneEmpty(topupIndex, true)
        return
      }
      this.setPhoneEmpty(topupIndex, false)

      // czy numer ma dobrą długość
      if (ToolsClass.cleanPhoneNumber(phone).length < TOPUP_PHONE_WITHOUT_PREFIX_LENGTH) {
        this.setPhoneValid(topupIndex, false)
        return
      }
      // czy numer jest prawidłowy
      if (!ToolsClass.isPhoneNumberValid(phone)) {
        this.setPhoneValid(topupIndex, false)
        return
      }
      this.setPhoneValid(topupIndex, true)

      // czy numer jest już w kontaktach
      if (this.isUserLogged && this.isPhoneInContacts(phone)) {
        let contact = this.getContactByPhone(phone)
        this.setPhoneContact(topupIndex, contact)
      }

      // kasujemy duplikaty
      let phonesInTopup = this.form.topup.map(item => ToolsClass.cleanPhoneNumber(item.phone))
      let phoneDuplicates = phonesInTopup.filter(item => item === ToolsClass.cleanPhoneNumber(phone))
      if (phoneDuplicates.length > 1) {
        this.form.topup.splice(topupIndex, 1)
        return
      }

      // checkphone w backendzie
      this.setCheckPhoneProgressFlag(topupIndex, true)
      let data = {
        operator: this.$VUE_APP_OPERATOR,
        account: `48${ToolsClass.cleanPhoneNumber(phone)}`,
        amount: 5.00
      }
      this.checkPhoneStatus(data)
        .then(response => {
          if (response.body.status === 1) {
            this.setCheckPhoneStatusFlag(topupIndex, true)
          } else {
            this.setCheckPhoneStatusFlag(topupIndex, false)
          }
          this.setCheckPhoneProgressFlag(topupIndex, false)
        })
        .catch(() => {
          this.setCheckPhoneStatusFlag(topupIndex, false)
        })
    },
    isFormValid () {
      let phonesValid = false
      let phonesCheckPhoneValid = false
      let contactNameValid = false

      if (this.form.topup.length) {
        phonesValid = this.form.topup
          .map(topup => Boolean(topup.isPhoneValid))
          .reduce((acc, status) => {
            return acc && status
          }, true)

        phonesCheckPhoneValid = this.form.topup
          .map(topup => Boolean(topup.isCheckPhoneStatusValid))
          .reduce((acc, status) => {
            return acc && status
          }, true)

        contactNameValid = this.form.topup
          .map(topup => {
            if (topup.saveContact) {
              return !!topup.contact.name && topup.contact.name.length > 0
            }
            return true
          })
          .reduce((acc, status) => {
            return acc && status
          }, true)
      }

      let isEmailSetAndValid = (() => {
        if (this.form.email === null || !this.form.email.length) {
          return false
        }
        return !!this.form.isEmailValid
      })()

      return phonesValid && phonesCheckPhoneValid && isEmailSetAndValid && contactNameValid
    },
    submit (e) {
      e.preventDefault()
      this.formError = false
      this.checkEmail()

      if (IS_MAINTENANCE_GLOBAL) {
        return false
      }

      if (!this.isFormValid()) {
        this.formError = true
        return
      }
      GoogleAnalytics.addFormSubmitEvent()
      this.setStage1Data(this.form)
        .then(() => {
          this.SET_TOPUP_STAGE(2)
        })
    }
  },
  created () {
    if (this.stage > 3) {
      this.RESET_TOPUP()
    }
    if (this.formData !== null) {
      this.form = ToolsClass.deepExtend(this.formData)
    } else {
      this.addTopup()
      this.supportLangParam()
      this.supportPhoneParam()
      this.supportAmountParam()
      if (!this.isUserLogged) {
        this.supportEmailParam()
      }
      this.supportTokenParam()
    }
  },
  mounted () {
    let topupField = this.$refs.topupPhoneInput
    if (topupField) {
      topupField[0].focus()
    }
    if (Object.keys(this.$route.query).length) {
      this.$router.replace({})
    }
    if (this.isUserLogged) {
      this.form.email = this.userEmail
      this.checkEmail()
    }
  }
}
