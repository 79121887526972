import { ToolsClass } from '@/common/class/tools.class'
import { DateClass } from '@/common/class/date.class'
import { BANNERS_VISIBILITY } from '@/common/const/banners.const'
import { isUserLogged } from '@/common/guards/user.guard'

let slideChangeTimestamp = null
let slideProgressHandler = null

export default {
  data () {
    return {
      activeIndex: 0,
      translateIndex: 0,
      slideChangeHandler: null,
      slideProgressTime: null,
      windowSize: null
    }
  },
  computed: {
    config () {
      return this.$VUE_APP_BANNERS
    },
    lang () {
      return this.$i18n ? this.$i18n.locale() : this.config.defaultLang
    },
    activeBanners () {
      const config = ToolsClass.deepExtend(this.config.images[this.lang])
      if (!config || !config.items || !(config.items instanceof Array)) {
        return []
      }
      return config.items.filter(banner => {
        let dateStart = banner.dateStart || null
        let dateEnd = banner.dateEnd || null
        banner.img = banner.img[this.windowSize]
        if (DateClass.isDateActive(dateStart, dateEnd)) {
          switch (banner.visibility) {
            case BANNERS_VISIBILITY.logged:
              return isUserLogged()
            case BANNERS_VISIBILITY.notLogged:
              return !isUserLogged()
            default:
              return true
          }
        }
      })
    },
    bannersList () {
      return [this.activeBanners[this.bannersLength - 1], ...this.activeBanners, this.activeBanners[0]]
    },
    bannersLength () {
      return this.activeBanners.length
    }
  },
  methods: {
    setWindowSize () {
      this.windowSize = Object.keys(this.config.breakpoints).reverse().find(size => window.innerWidth >= this.config.breakpoints[size])
    },
    getBannerSrc (image) {
      return `${this.config.path}/${this.lang}/${image}`
    },
    getTranslateValue () {
      return 'translateX(' + ((this.translateIndex + 1) * 100 * -1) + '%)'
    },
    getSlidePercentProgress (index) {
      if (!this.slideProgressTime || index !== this.activeIndex) {
        return 0
      }
      return Math.round(this.slideProgressTime / this.config.rotationTime * 100)
    },
    showSideControls () {
      return !!this.config.sideControls && this.bannersLength > 1
    },
    showBottomControls () {
      return !!this.config.bottomControls && this.bannersLength > 1
    },
    isSmoothSlideTransition () {
      return this.config.slideTransition === 'smooth'
    },
    trackSlideProgress () {
      return !!this.config.trackProgress
    },
    isSlideActive (index) {
      return index === this.activeIndex
    },
    setActiveSlide (index) {
      if (typeof index === 'undefined') {
        index = this.activeIndex + 1
      } else {
        clearInterval(this.slideChangeHandler)
        this.slideProgressTime = 0
      }
      let fixedIndex = index
      if (fixedIndex < 0) {
        fixedIndex = this.bannersLength - 1
      }
      if (fixedIndex >= this.bannersLength) {
        fixedIndex = 0
      }
      this.activeIndex = fixedIndex
      this.translateIndex = fixedIndex
      if (this.isSmoothSlideTransition() && index !== fixedIndex) {
        this.translateIndex = index
        this.turnSliderToIndex(fixedIndex)
      }
      this.startSlideChangeTimeout(true)
    },
    turnSliderToIndex (index) {
      const sliderEl = document.querySelector('.banners-slider__container .smooth')
      setTimeout(() => {
        const className = 'no-smooth'
        sliderEl.classList.add(className)
        this.translateIndex = index
        setTimeout(() => {
          sliderEl.classList.remove(className)
        }, 100)
      }, 200)
    },
    setPrevSlideActive () {
      this.setActiveSlide(this.activeIndex - 1)
    },
    setNextSlideActive () {
      this.setActiveSlide(this.activeIndex + 1)
    },
    startSlideChangeTimeout (force) {
      let rotationTime = this.config.rotationTime
      if (!force && !this.config.pauseOnHover) {
        return
      }
      if (this.bannersLength <= 1) {
        return
      }
      if (this.trackSlideProgress) {
        if (this.slideProgressTime && this.slideProgressTime < (this.config.rotationTime * 0.99)) {
          slideChangeTimestamp = +new Date() - this.slideProgressTime
          rotationTime = this.config.rotationTime - this.slideProgressTime
        } else {
          slideChangeTimestamp = +new Date()
          this.slideProgressTime = 0
        }
      }
      this.slideChangeHandler = setTimeout(this.setActiveSlide, rotationTime)
      if (this.trackSlideProgress) {
        clearInterval(slideProgressHandler)
        slideProgressHandler = setInterval(() => {
          this.slideProgressTime = +new Date() - slideChangeTimestamp
        }, this.config.rotationTime / 100)
      }
    },
    stopSlideChangeTimeout (force) {
      if (!force && !this.config.pauseOnHover) {
        return
      }
      clearTimeout(this.slideChangeHandler)
      clearInterval(slideProgressHandler)
      this.slideChangeHandler = null
    },
    toggleSlideChangeTimeout () {
      if (this.slideChangeHandler) {
        this.stopSlideChangeTimeout()
      } else {
        this.startSlideChangeTimeout()
      }
    }
  },
  created () {
    this.setWindowSize()
  },
  mounted () {
    this.startSlideChangeTimeout(true)
    window.addEventListener('resize', this.setWindowSize)
  },
  beforeDestroy () {
    this.stopSlideChangeTimeout(true)
    window.removeEventListener('resize', this.setWindowSize)
  }
}
