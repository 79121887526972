export default {
  methods: {
    supportEmailParam () {
      let email = this.$route.query.email
      if (!email) {
        return
      }

      this.form.email = email
      this.checkEmail()
    }
  }
}
