export default {
  methods: {
    supportAmountParam () {
      let amount = this.$route.query.amount
      if (!amount) {
        return
      }
      amount = parseInt(amount, 10)
      if (this.$VUE_APP_OPERATOR === 'ORANGE') {
        amount = amount / 100
      }
      this.form.topup[0].amount = amount
    }
  }
}
