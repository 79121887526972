<template>
  <div class="steps-wrapper">
    <div class="step"
         :class="{active: isCurrentStep(1), completed: isStepCompleted(1)}"
         :aria-hidden="!isCurrentStep(1)"
         @click="goToStage(1)">
      <div class="number">
        <span aria-hidden="true">1</span>
      </div>
      <div class="name">
        <p>{{ $t('view.component.topup.single.steps.step1') }}<span class="name-aria-description">, {{ $t('view.accessibilities.readerWay.stepsDescriptions.step1') }}</span></p>
      </div>
    </div>
    <div class="line first" :class="{active: isCurrentStep(2), completed: isStepCompleted(2)}"></div>
    <div class="step"
         :class="{active: isCurrentStep(2), completed: isStepCompleted(2)}"
         :aria-hidden="!isCurrentStep(2)"
         @click="goToStage(2)">
      <div class="number">
        <span aria-hidden="true">2</span>
      </div>
      <div class="name">
        <p>{{ $t('view.component.topup.single.steps.step2') }}<span class="name-aria-description">, {{ $t('view.accessibilities.readerWay.stepsDescriptions.step2') }}</span></p>
      </div>
    </div>
    <div class="line second" :class="{active: isCurrentStep(3), completed: isStepCompleted(3)}"></div>
    <div class="step" :class="{active: isCurrentStep(3), completed: isStepCompleted(3)}" :aria-hidden="!isCurrentStep(3)">
      <div class="number">
        <span aria-hidden="true">3</span>
      </div>
      <div class="name">
        <p>{{ $t('view.component.topup.single.steps.step3') }}<span class="name-aria-description">, {{ $t('view.accessibilities.readerWay.stepsDescriptions.step3') }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import TopupProgressMixin from '@/common/mixins/Topup/Progress.mixin'
import { ROUTING } from '@/orange/const/routing.const'
import { mapMutations } from 'vuex'

export default {
  name: 'TopupSingleSteps',
  mixins: [TopupProgressMixin],
  methods: {
    ...mapMutations(['SET_TOPUP_STAGE']),
    goToStage (stage) {
      if (stage >= this.stage) {
        return
      }
      switch (stage) {
        case 2:
          this.SET_TOPUP_STAGE(2)
          this.$router.push(ROUTING.topup.single.step2)
          break
        case 1:
          this.SET_TOPUP_STAGE(1)
          this.$router.push(ROUTING.topup.single.step1)
          break
      }
    }
  }
}
</script>
<style lang="scss" src="../../../assets/scss/components/_topup-steps.scss" scoped></style>
<style lang="scss" scoped>

  .name-aria-description {
    position: absolute;
    height: 0;
    width: 0;
    color: transparent;
    background-color: transparent;
    margin:0;
    padding: 0
  }

</style>
