import { mapActions, mapMutations } from 'vuex'
import { TOPUP_PHONE_WITH_PREFIX_LENGTH, TOPUP_PHONE_WITHOUT_PREFIX_LENGTH } from '@/common/const/topup.const'

export default {
  methods: {
    ...mapMutations(['SET_TOPUP_LOADING']),
    ...mapActions(['checkToken']),
    supportTokenParam () {
      let token = this.$route.query.token
      if (!token) {
        return
      }
      this.SET_TOPUP_LOADING(true)
      this.checkToken(token)
        .then(response => {
          let phone = String(response.body)
          if (phone.length < TOPUP_PHONE_WITHOUT_PREFIX_LENGTH || phone.length > TOPUP_PHONE_WITH_PREFIX_LENGTH) {
            return
          }
          phone = phone.substr(-9)
          this.form.topup[0].phone = phone
          this.checkPhone(0)
        })
        .finally(() => {
          this.SET_TOPUP_LOADING(false)
        })
    }
  }
}
